<template>
    <div>
        <b-form-group :id="`${id}-form-group`" :state="state">
            <b-form-input
                :id="`${id}-form-input`"
                class="form-input"
                :value="inputText"
                :disabled="disabled"
                :state="state"
                :required="$attrs.required"
                :readonly="formInputReadOnly"
                :placeHolder="placeHolder"
                @focus="onInputFocus"
                @blur="onInputBlur"
            ></b-form-input>
            <b-popover
                :id="`${id}-popover`"
                :target="`${id}-form-input`"
                ref="popover"
                :container="`${id}-form-group`"
                placement="bottom"
                :disabled="disabled"
                triggers="focus blur"
                custom-class="custom-selector-popover"
                boundary-padding="0"
            >
                <!-- Search Bar -->
                <div>
                    <b-row class="mb-2">
                        <b-col>
                            <b-input-group>
                                <b-input-group-prepend is-text class="search-input-prepend">
                                    <b-icon icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input
                                    trim
                                    :id="`${id}-search-input`"
                                    ref="searchInput"
                                    class="search-input"
                                    type="search"
                                    v-model="searchText"
                                    placeholder="Search..."
                                    @keyup.enter="onSearchEnter"
                                >
                                </b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </div>
                <!-- Options List -->
                <div class="options-list">
                    <div ref="optionsListContents" class="options-list-contents">
                        <b-row>
                            <b-col>
                                <ul>
                                    <li
                                        v-for="option in optionsProcessed"
                                        :key="option[optionValueKey]"
                                        class="pointer options-list-contents-item"
                                        @click="onOptionSelected(option)"
                                    >
                                        <slot name="option" :option="option"></slot>
                                    </li>
                                    <li
                                        v-if="optionsProcessed.length === 0"
                                        class="options-list-contents-item"
                                    >
                                        <div class="options-list-contents-item-details">
                                            <div>
                                                <slot name="no-options-found">
                                                    No options found
                                                </slot>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-popover>
        </b-form-group>
    </div>
</template>

<script>
import {uniqBy} from 'lodash';
import {setSearchResultProperties} from '@/utilities/search';

/**
 * This component implements a custom selector that has search capabilities.
 * It is comprised of a read-only input field and a popover that has a
 * searchable list of options.
 *
 * @emits input - Emitted when the model value changes
 * @slot option - Determines how the options look and behave in the option list
 *   @binding option - The option object
 *
 * @slot no-options-found - Displays when no options are found
 */
export default {
    name: 'Selector',
    emits: ['input'],
    props: {
        /**
         * The unique ID for the selector
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * The model value
         */
        value: {
            type: [Number, String],
            required: false,
        },
        /**
         * Determines if the selector is disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * The options available in the selector
         */
        options: {
            type: Array,
            required: true,
        },
        /**
         * The property in the options objects that denote the option's value.
         * options[optionValueKey] should be unique for all options.
         */
        optionValueKey: {
            type: String,
            required: true,
        },
        /**
         * The property in the options objects that describe how the option
         * should be displayed in the selector list.
         */
        optionDisplayKey: {
            type: String,
            required: true,
        },
        /**
         * The property in the options objects that determine if the option
         * should be disabled.
         */
        optionDisabledKey: {
            type: String,
            required: false,
        },
        /**
         * The properties of the options objects that will be searched
         */
        propertiesToSearch: {
            type: Array,
            default: null,
        },
        /**
         * Placeholder text for the search input
         */
        placeHolder: {
            type: String,
            default: 'Select option',
        },
        /**
         * The validation state of the selector
         */
        state: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            formInputReadOnly: false,
            searchText: '',
            optionsProcessed: [],
        };
    },
    watch: {
        $props: {
            immediate: true,
            handler() {
                this.validateProps();
            },
        },
        searchText(value) {
            this.getSearchResults(value);
        },
    },
    created() {},
    mounted() {
        /**
         * When the popover is shown set the focus to the search input
         */
        this.$root.$on('bv::popover::shown', (bvEventObj) => {
            if (bvEventObj.componentId === `${this.id}-popover`) {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.$el.focus();
                }
            }
        });
    },
    computed: {
        /**
         * Determines what to display in the read-only input field
         */
        inputText() {
            let inputText = '';
            if (this.value || this.value === 0) {
                let option = this.options.find((o) => {
                    return o[this.optionValueKey] == this.value;
                });
                inputText = (option && option[this.optionDisplayKey]) || '';
            } else {
                inputText = this.placeHolder;
            }
            return inputText;
        },
    },
    methods: {
        /**
         * Validate properties that depend on other properties. All other
         * valdation is done in the props section.
         */
        validateProps() {
            let error = '';

            // Validate the required option properties exist
            [this.optionValueKey, this.optionDisplayKey].forEach((key) => {
                if (
                    this.options.some((o) => {
                        return !Object.keys(o).includes(key);
                    })
                ) {
                    error += `Some options do not have the property ${key}\n`;
                    throw Error(error);
                }
            });

            // Validate the uniqueness of the option[optionValueKey] values
            let uniqueValues = uniqBy(this.options, this.optionValueKey);
            if (uniqueValues.length !== this.options.length) {
                error +=
                    `Duplicate ${this.optionValueKey} values found. ` +
                    `${this.optionValueKey} values must be unique.`;
                throw Error(error);
            }
        },
        /**
         * Handle the input focus event
         */
        onInputFocus() {
            this.formInputReadOnly = true;
            this.searchText = '';
            this.getSearchResults(this.searchText);
        },
        /**
         * Handle the input blur event
         */
        onInputBlur() {
            this.formInputReadOnly = false;
        },
        /**
         * Get the search results for the given search text and scrolls the
         * list to the top
         *
         * @param {String} searchText
         */
        getSearchResults(searchText) {
            this.optionsProcessed = setSearchResultProperties(
                searchText,
                this.options,
                this.propertiesToSearch
            );

            if (searchText) {
                this.optionsProcessed = this.optionsProcessed.filter((option) => {
                    return option.textFound;
                });
            }

            if (this.$refs.optionsListContents) {
                this.$refs.optionsListContents.scrollTop = 0;
            }
        },
        /**
         * Handle when Enter is pressed while the search bar is in focus.
         * This will attempt to select the first option if there are any
         * options showing.
         */
        onSearchEnter(event) {
            if (this.optionsProcessed.length) {
                this.onOptionSelected(this.optionsProcessed[0]);
            }
            this.$emit('search-enter', event);
        },
        /**
         * Handle when an option selection is attempted. If the option is
         * not disabled it will be selected.
         *
         * @param {Object} option
         */
        onOptionSelected(option) {
            if (!option[this.optionDisabledKey]) {
                // Update model value
                this.$emit('input', option[this.optionValueKey]);
                this.$root.$emit('bv::hide::popover', `${this.id}-popover`);
            }
        },
    },
};
</script>

<style lang="scss">
@import '../assets/css/variables';

.custom-selector-popover {
    &.popover {
        width: 100%;
        max-width: 100%;
        margin-top: 0.25rem;
        border: 1px solid $gray-light;

        .popover-body {
            padding: 0.75rem;
        }

        .arrow {
            display: none;
        }
    }
}
</style>

<style lang="scss" scoped>
@import '../assets/css/variables';
@import '../assets/css/typography';

.options-list {
    &-contents {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
        }

        li {
            &:first-of-type,
            &:hover {
                background-color: $primary-light;
            }

            &:not(hover) {
                background-color: white;
            }
        }

        &-item {
            padding: 0.25rem 0rem;
        }
    }
}
</style>
