export const OrderSubStatus = Object.freeze({
    /**RESERVED is not part of sub status but is set as the status when none substatus */
    RESERVED: 'Reserved',
    NONE: null,
    IN_CART: 'In Cart',
    ORDERED: 'Ordered',
    CANCELLED: 'Canceled',
    CONSUMED: 'Consumed',
    PREORDER: 'Pre-Order',
    PREORDER_RESERVATION: 'Pre-Order Reservation',
});
